/* .react-selectApp-style {
    z-index: 1200 !important;
} */

.select__menu {
    z-index: 1600 !important;
}

.swal2-container {
    z-index: 1600 !important;
}

body {
    overflow: auto;
    scrollbar-width: none;
}
::-webkit-scrollbar {
    width: 0;
}



