.activeStyle {
    text-decoration: none;
}

.activeStyle .MuiListItemButton-root {
    color: #fff;
    background: #7D112A;
}

.activeStyle .MuiListItemButton-root:hover {
    background-color: #7D112A;
    color: #fff;
}

.activeStyle .MuiListItemButton-root .MuiListItemIcon-root {
    color: #D5BF7D;
}

.notActiveStyle .MuiListItemButton-root .MuiListItemIcon-root {
    color: #fff;
}

.MuiDrawer-docked .MuiDrawer-paper {
    background-color: #272626;
}

.notActiveStyle {
    text-decoration: none;
    color: #fff !important;
}

.footer {
    position: relative;
    z-index: 100 !important;
}